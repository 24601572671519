import Innovation from './Img/Innovation.png';
import Reliability from './Img/Reliablity.png';
import ValueStrength from './Img/Values-strength.png';
import Customers from './Img/Our-customers.png';
import HomeClinicStaffBG from './Img/home_clinic_staff_bg.jpg';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import './timeline.css';

function Aboutus(){
  const css = `
  #DetailsDiv{
    display: flex; 
    flex-direction: row; 
    align-items: center; 
    width: 100%;
  }
  #DetailsDivR{
    display: flex; 
    flex-direction: row; 
    align-items: center; 
    width: 100%;
  }
  #resizeImage{
    height: 100%;
    width: 100%;
    max-width:564px;
    max-height:366px;
}
    
  @media (max-width: 1020px){
      .backimage{
        // background-image:url("https://picsum.photos/200/300");
        // background-color:'red';
      }
        #DetailsDiv{
          display: flex; 
          flex-direction: column; 
          align-items: center; 
          width: 100%;
        }
        #DetailsDivR{
          display: flex; 
          flex-direction: column-reverse; 
          align-items: center; 
          width: 100%;
        }
      }
    }
    @media (max-width: 650px) {
      // .backimage {
      //     background-image: url("https://picsum.photos/200/300")
      // }
    }
    @media (min-width: 750px) {
      // .backimage {
      //     background-image: url("https://picsum.photos/200");
      // }
  }`;


    return(

        <>
        <style scoped>{css}</style>
<div  id="missionVision" style={{paddingTop:"70px", paddingBottom:"30px", backgroundColor:"#e0f0f9"}}>
<div id='DetailsDiv' >
<img id='resizeImage' class="scale-with-grid" src={Reliability} alt="Innovation" />

<div style={{display:'flex', flexDirection:'column', alignItems:'start', width:'100%'}}>

<h3 style={{fontFamily:'Exo, sans-serif', paddingLeft:'5%', paddingRight:'5%', color:'#9b2687', fontSize:'32px'}}>Mission & Vision</h3>
<p class="big" style={{fontFamily:'Roboto, sans-serif',color:'#626262',paddingLeft:'5%', paddingRight:'5%', textAlign:'justify', fontSize:'100%', lineHeight:'2'}}>

Mission & Vision
At Canmed Healthcare, we’re always looking forward to future technology, to provide the best solutions, products, and services designed around the needs of our customers, so we deliver the best results, faster and safer. We take time and responsibility to understand our client’s needs, so we deliver solutions that meet and create market demand. Always our commitment remains grounded.

To be one of India’s first choice of supplier in the field of Oncology Medical devices.
</p>
</div>
</div>
</div>


<div id='valueStrength' style={{paddingTop:"70px", paddingBottom:"30px", backgroundColor:"#e0f0f9"}}>
<div id='DetailsDivR'>
<div style={{display:'flex', flexDirection:'column', alignItems:'start', width:'100%'}}>

<h3 style={{ fontFamily:' Exo, sans-serif', paddingLeft:'5%', paddingRight:'5%', color:'#9b2687', fontSize:'32px'}}>Values & Strength</h3>
<p class="big" style={{paddingLeft:'5%', paddingRight:'5%', textAlign:'justify', fontSize:'100%', lineHeight:'2',color:'#626262', fontFamily:'Roboto,sans-serif'}}>
We work with strong value with people in the mind, commitment to the people is core to all our operations, this results in long term association with employees and customers. Our Cultural values combine with Passion, Openness, Excellence and Ownership are needed to meet the future challenges and requirements to grow in challenging environment.   
</p>
</div>
<div style={{display:'flex', flexDirection:'column', alignItems:'center', width:'100%'}}>
<img id='resizeImage'  class="scale-with-grid" src={ValueStrength} alt="Quality" />
</div>
</div>
</div>


<div id='ourCustomers' style={{paddingTop:"70px", paddingBottom:"30px", backgroundColor:"#e0f0f9"}}>
<div id='DetailsDiv'>
<img id='resizeImage' src={Customers} alt="Reliability" />
<div style={{display:'flex', flexDirection:'column', alignItems:'start', width:'100%'}}>
<h3 style={{ fontFamily:'Exo, sans-serif', paddingLeft:'5%', paddingRight:'5%', color:'#9b2687', fontSize:'32px'}}>Our customers</h3>
<p class="big" style={{paddingLeft:'5%', paddingRight:'5%', textAlign:'justify', fontSize:'100%',color:'#626262', lineHeight:'2'}}>
Our customers
CANMED is committed to achieve customer satisfaction through good insight on their requirements and always continue to improve Product and Quality Management System. Our customers can rely on our quality and long-lasting durability of the devices. We welcome you to share with us your story about our products, our business so we could offer the best solutions for most diverse situations.

CANMED is a highly innovative company as it closely collaborates in working with doctors and healthcare professionals, who are involved in every step of product development, from the idea to the finished product. Thanks to these unique partnerships and a continued focus on the well-being of the patient.
</p>
</div>
</div>

</div>
{/* <div  style={{paddingTop:"70px", paddingBottom:"30px", backgroundColor:"#f1faff", paddingLeft:'5%', paddingRight:'5%'}}> */}
    {/* <img class="mfn-parallax" src={HomeClinicStaffBG} alt="" style={{width: "479.333px"}}/> */}
        {/* <div class="section_wrapper mcb-section-inner"> */}
                        {/* <div style={{color:'#9b2687', textAlign:'left'}}><h3>Milestone</h3>
                        </div> */}
                        {/* </div> */}
                        {/* <div >
                            <ul class="timeline_items">
                            <li><h3>2019</h3>
                            <div class="desc">Started Research on Oral Cooling System</div>
                            </li><li><h3>2020</h3>
                            <div class="desc">Continuation of Oral Cooling System Research</div>
                            </li><li><h3>2021</h3>
                            <div class="desc">Oral Cooling System prototype development completed,  and clinical trials conducted</div>
                            </li><li><h3>2022</h3>
                            <div class="desc">R &amp; D of our future products in progress</div>
                            </li></ul>
                        </div> */}
                    {/* </div> */}



  {/* <div class="container right">
    <div class="content">
      <h2>2016</h2>
      <p>Lorem ipsum..</p>
    </div>
  </div> */}

<div id="milestones" style={{paddingTop:"70px", paddingBottom:"30px", backgroundColor:"#f1faff", paddingLeft:'5%', paddingRight:'5%'}}>
<div style={{color:'#9b2687', textAlign:'left'}}><h3 style={{fontSize:'32px', fontFamily:'Exo, sans-serif'}}>Milestone</h3>
                        </div>
<div style={{height:'150%',width:'0.01%', backgroundColor:'rgb(169,184,195) 100%);', border:'2px solid #a9b8c352', position:'absolute', left:'50%'}}>
</div>        
{/* ---------------- */}
        <div style={{width:'5%',  height:'5%'}}>
        <div style={{borderRadius:"50%", zIndex:1, backgroundColor:'#f1faff',border:'4px solid #9b2687',  height:'8px', width:'8px', position:'absolute', marginTop:'20px', left:"49.6%"}}></div>
        </div>
        <div style={{height:'0.01%',width:'6%', backgroundColor:'rgb(169,184,195) 100%)', border:'2px solid #a9b8c352', position:'absolute', marginTop:'25px', left:'50%'}}>
        </div>

        <div style={{ flexDirection:'column', borderBottom:'1px solid black',  width:'40%',display:'flex',  justifyContent:'flex-start', alignContent:'end',  alignItems:'start', float:'right'}}>
            <h3 style={{ fontFamily:'Exo, sans-serif', color:'#9b2687', fontSize:'32px'}}>2019</h3>
            <p  style={{fontSize:'100%', color:'#626262', fontFamily:'Roboto, sans-serif'}}>Started Research on Oral Cooling System</p>
        </div>
{/* ------------------------ */}
<div style={{width:'5%',  height:'5%'}}>
        <div style={{borderRadius:"50%", zIndex:1, backgroundColor:'#f1faff',border:'4px solid #9b2687',  height:'8px', width:'8px', position:'absolute', marginTop:'180px', left:"49.6%"}}></div>
        </div>
        <div style={{height:'0.01%',width:'6%', backgroundColor:'rgb(169,184,195) 100%)', border:'2px solid #a9b8c352', position:'absolute', marginTop:'185px', right:'50%'}}>
        </div>

        <div style={{ flexDirection:'column', borderBottom:'1px solid black',  width:'40%',display:'flex',  justifyContent:'flex-start', marginTop:'160px', alignContent:'end',  alignItems:'end'}}>
            <h3 style={{ fontFamily:'Exo, sans-serif', color:'#9b2687', fontSize:'32px'}}>2020</h3>
            <p style={{fontSize:'100%', color:'#626262', fontFamily:'Roboto, sans-serif'}} >Continuation of Oral Cooling System Research</p>
        </div>

{/* ---------------- */}
<div style={{width:'5%',  height:'5%'}}>
        <div style={{borderRadius:"50%", zIndex:1, backgroundColor:'#f1faff',border:'4px solid #9b2687',  height:'8px', width:'8px', position:'absolute', marginTop:'20px', left:"49.6%"}}></div>
        </div>
        <div style={{height:'0.01%',width:'6%', backgroundColor:'rgb(169,184,195) 100%);', border:'2px solid #a9b8c352', position:'absolute', marginTop:'25px', left:'50%', backgroundColor:'#f1faff'}}>
        </div>

        <div style={{ flexDirection:'column', borderBottom:'1px solid black',  width:'40%',display:'flex',  justifyContent:'flex-start', alignContent:'end',  alignItems:'start', float:'right'}}>
            <h3 style={{ fontFamily:'Exo, sans-serif', color:'#9b2687', fontSize:'32px'}}>2021</h3>
            <p style={{fontSize:'100%', color:'#626262', fontFamily:'Roboto, sans-serif'}}>Got Established; Product development 
            </p>
        </div>
{/* ------------------------ */}
<div style={{width:'5%',  height:'5%'}}>
        <div style={{borderRadius:"50%", zIndex:1, backgroundColor:'#f1faff',border:'4px solid #9b2687',  height:'8px', width:'8px', position:'absolute', marginTop:'180px', left:"49.6%"}}></div>
        </div>
        <div style={{height:'0.01%',width:'6%', backgroundColor:'rgb(169,184,195) 100%);', border:'2px solid #a9b8c352', position:'absolute', marginTop:'185px', right:'50%'}}>
        </div>

        <div style={{ flexDirection:'column', borderBottom:'1px solid black',  width:'40%',display:'flex',  justifyContent:'flex-start', marginTop:'160px', alignContent:'end',  alignItems:'end'}}>
            <h3 style={{ fontFamily:'Exo, sans-serif', color:'#9b2687', fontSize:'32px'}}>2022</h3>
            <p style={{fontSize:'100%', color:'#626262', fontFamily:'Roboto, sans-serif'}} >Product development continued;</p>
</div>

{/* ---------------- */}
<div style={{width:'5%',  height:'5%'}}>
        <div style={{borderRadius:"50%", zIndex:1, backgroundColor:'#f1faff',border:'4px solid #9b2687',  height:'8px', width:'8px', position:'absolute', marginTop:'20px', left:"49.6%"}}></div>
        </div>
        <div style={{height:'0.01%',width:'6%', backgroundColor:'rgb(169,184,195) 100%);', border:'2px solid #a9b8c352', position:'absolute', marginTop:'25px', left:'50%', backgroundColor:'#f1faff'}}>
        </div>

        <div style={{ flexDirection:'column', borderBottom:'1px solid black',  width:'40%',display:'flex',  justifyContent:'flex-start', alignContent:'end',  alignItems:'start', float:'right'}}>
            <h3 style={{ fontFamily:'Exo, sans-serif', color:'#9b2687', fontSize:'32px'}}>2023</h3>
            <p style={{fontSize:'100%', color:'#626262', fontFamily:'Roboto, sans-serif'}}>New product research started 
            </p>
        </div>
{/* ------------------------ */}
<div style={{width:'5%',  height:'5%'}}>
        <div style={{borderRadius:"50%", zIndex:1, backgroundColor:'#f1faff',border:'4px solid #9b2687',  height:'8px', width:'8px', position:'absolute', marginTop:'180px', left:"49.6%"}}></div>
        </div>
        <div style={{height:'0.01%',width:'6%', backgroundColor:'rgb(169,184,195) 100%);', border:'2px solid #a9b8c352', position:'absolute', marginTop:'185px', right:'50%'}}>
        </div>

        <div style={{ flexDirection:'column', borderBottom:'1px solid black',  width:'40%',display:'flex',  justifyContent:'flex-start', marginTop:'160px', alignContent:'end',  alignItems:'end'}}>
            <h3 style={{ fontFamily:'Exo, sans-serif', color:'#9b2687', fontSize:'32px'}}>2024</h3>
            <p style={{fontSize:'100%', color:'#626262', fontFamily:'Roboto, sans-serif'}} >Oral Cooling system launching </p>
            
</div>

</div>


        {/* <VerticalTimeline
                style={{backgroundColor:'#f1faff', color:'#f1faff', height:'10px'}}

        layout='1-column-left'
        lineColor='#f1faff'
        animate={false}>
        <VerticalTimelineElement
                style={{backgroundColor:'#f1faff', color:'#f1faff', height:'10px'}}

    className="vertical-timeline-element--work"
    contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff', height:'100%' }}
    contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)',height:'50px',width:'50px' }}
    iconStyle={{ background: '#f1faff', color: '#fff' }}
    
  >
    <h3 className="vertical-timeline-element-title">2019</h3>
    <h4 className="vertical-timeline-element-subtitle">Started Research on Oral Cooling System</h4>
    <p>
      System
    </p>
  </VerticalTimelineElement>
            </VerticalTimeline> */}
            <div style={{ display:'flex', width:'100%', flexDirection:'column', fontSize:'12px',  alignItems:'center', paddingTop:"70px", paddingBottom:"30px",  backgroundColor:"#e0f0f9"}}>
  <p style={{}}>© 2021 Canmed Healthcare Bangalore. All Rights Reserved </p>
</div>
        </>
    );

}

export default Aboutus;