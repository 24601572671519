import HomeClinicUnderLine from './Img/home_clinic_hr_l.png';
import { Link } from 'react-router-dom';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faMapMarker, faEnvelope, faLink } from '@fortawesome/free-solid-svg-icons';
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { CircularProgress } from '@mui/joy';
import Alert from '@mui/joy/Alert';
import AspectRatio from '@mui/joy/AspectRatio';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import DialogActions from '@mui/joy/DialogActions';
import DialogContent from '@mui/joy/DialogContent';
import DialogContentText from '@mui/joy/DialogContent';
import DialogTitle from '@mui/joy/DialogTitle';
import Button from '@mui/joy/Button';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';

function Contactus(){
  const css = `
  #DetailsDiv{
    display: flex; 
    flex-direction: row; 
    justify-content: space-around;
  }
  #DetailsDivR{
    display: flex; 
    flex-direction: row; 
    align-items: center; 
    width: 100%;
  }
    
  @media (max-width: 1020px){
      .backimage{
        // background-image:url("https://picsum.photos/200/300");
        // background-color:'red';
      }
        #DetailsDiv{
          display: flex; 
          flex-direction: column; 
         
        }
        #DetailsDivR{
          display: flex; 
          flex-direction: column-reverse; 
          align-items: center; 
          width: 100%;
        }
      }
    }
    @media (max-width: 650px) {
      // .backimage {
      //     background-image: url("https://picsum.photos/200/300")
      // }
    }
    @media (min-width: 750px) {
      // .backimage {
      //     background-image: url("https://picsum.photos/200");
      // }
  }`;

    const BootstrapButton = styled(Button)({
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        color:'#9b2687',
        padding: '6px 12px',
        border: '1px solid #9b2687',
        lineHeight: 1.5,
        borderColor: '#9b2687',
        fontFamily: [
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
          backgroundColor: '#9b2687',
        //   borderColor: '#0062cc',
          color:'white',
          boxShadow: 'none',
        },
        '&:active': {
          color:'white',
          boxShadow: 'none',
          backgroundColor: '#9b2687',
          borderColor: '#9b2687',
        },
        
      });
      
    const [progress, setProgress] = useState(false);

    const [open, setOpen] = useState(false);
    const [openFailed, setOpenFailed] = useState(false);

    const form = useRef();

    const handleClickOpen = () => {
        setOpen(true);
    };
    
    const handleClickOpenFailed = () => {
        setOpenFailed(true);
    };
    
    const handleCloseFailed = () => {
        setOpenFailed(false);
    };
    
    const handleClose = () => {
        setOpen(false);
    };
    
    
    const sendEmail = (e) => {
        e.preventDefault();
        setProgress(true)
        emailjs
          .sendForm('service_7jmc2he', 'template_xomk45a', form.current, {
            publicKey: '3R2uINaDNtDIu5ZMV',
          })
          .then(
            () => {
            setProgress(false)
              console.log('SUCCESS!');
              handleClickOpen();
            },
            (error) => {    
            setProgress(false)
              console.log('FAILED...', error.text);
              handleClickOpenFailed();
            },
          );
      };
return(
    <>
      {/* <Alert variant="solid" color="success">
          Success
        </Alert> */}
<style scoped>{css}</style>
<Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{padding:'40px'}}
      >
        <DialogTitle id="alert-dialog-title" style={{display:'flex', alignItems:'center', alignSelf:'center',color:'green', fontSize:'32px', fontFamily:'Roboto, sans-serif', padding:'40px'}}>
          {"SUCCESS"}
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{color:'green', fontFamily:'Roboto, sans-serif', padding:'40px'}}>
            Thanks for Contacting Us!.
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ display:'flex', alignSelf:'center', width:"50px", paddingBottom:'40px'}}>
          <Button onClick={handleClose} >OK</Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={openFailed}
        onClose={handleCloseFailed}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{padding:'40px'}}
      >
        <DialogTitle id="alert-dialog-title" style={{display:'flex', alignItems:'center', alignSelf:'center',color:'red', fontSize:'32px', fontFamily:'Roboto, sans-serif', padding:'40px'}}>
          {"Failed"}
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{color:'red', fontFamily:'Roboto, sans-serif', padding:'40px'}}>
            Your form Submission is failed. Please retry!
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ display:'flex', alignSelf:'center', width:"50px", paddingBottom:'40px'}}>
          <Button onClick={handleCloseFailed} >OK</Button>
        </DialogActions>
      </Dialog>

    {progress&&
    <CircularProgress  size="lg"  style={{position:'absolute', top:'100%', left:'48%'}} />
    }
<div style={{ paddingLeft:'5%', paddingRight:'5%', paddingTop:"20px", paddingBottom:"30px", backgroundColor:"#e0f0f9"}}>
    <div class="section_wrapper mcb-section-inner">
                    <div class="column_attr clearfix align_left">
                        <h2 style={{
                              background:`url(${HomeClinicUnderLine})`,
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "bottom", 
                              fontFamily:'Exo, sans-serif',
                        paddingBottom: "25px",textAlign: "center",color:'#9b2687', fontSize:'32px'}}>
                            Career
                            </h2>

<p  style={{fontSize:'80%', color:'#9b2687', lineHeight:2}}>
<b><u>Hiring for Business Development Position in Various states</u></b>
</p>

<p style={{fontSize:'80%', color:'#626262', lineHeight:2}}>
•	Position: Business Development Exicutive / Manager 
</p>
<p style={{fontSize:'80%', color:'#626262', lineHeight:2}}>
•	Qualification: Graduates on Science/Engineering background preferred. Master’s Degree - an added advantage
</p>
<p style={{fontSize:'80%', color:'#626262', lineHeight:2}}>
•	Location: Karnataka, Tamil Nadu, Andhra Pradesh/Telangana, Kerala. 
</p>

</div>


<p style={{textDecoration:'underline',fontSize:'80%', color:'#9b2687', lineHeight:2,fontWeight:'bold'}}>
Roles & Responsibilities:
</p>
        
<p style={{fontSize:'80%', color:'#626262', lineHeight:2}}>
•	Responsible for a Business Development in state level 
</p>
<p style={{fontSize:'80%', color:'#626262', lineHeight:2}}>
• To Travel extensively 
</p>
<p style={{fontSize:'80%', color:'#626262', lineHeight:2}}>
•	Appointing and managing Distributors.
</p>
<p style={{fontSize:'80%', color:'#626262', lineHeight:2}}>
•	To meet the clients- hospital based 
</p>
<p style={{fontSize:'80%', color:'#626262', lineHeight:2}}>
•	To provide Application training to clients 
</p>
<p style={{fontSize:'80%', color:'#626262', lineHeight:2}}>
•	To Coordinate with Technical Team
</p>
<p style={{fontSize:'80%', color:'#626262', lineHeight:2}}>
•	⁠Maintaining records	
</p>

<p style={{textDecoration:'underline',fontSize:'80%', color:'#9b2687', lineHeight:2,fontWeight:'bold'}}>

Desired Skills:
</p>
        
<p style={{fontSize:'80%', color:'#626262', lineHeight:2}}>
•	Excellent communication skills is must both in English and local language 
</p>
<p style={{fontSize:'80%', color:'#626262', lineHeight:2}}>
•	⁠Basic knowledge on Computers - PPT & Excel
</p>
<p style={{fontSize:'80%', color:'#626262', lineHeight:2}}>
•	Minimum 2 years of Experiene in the similar field of Business Development/Sales
</p>
<p style={{fontSize:'14px',color:'#626262', lineHeight:2, fontFamily:'Poppins, sans-serif'}}>
Interested candidates can share their CVs/Resumes to            
<Link to='mailto:info@canmedhc.com'  style={{textDecoration:'none',display:'inline' ,color:'#9b2687'}} > info@canmedhc.com</Link>
</p>


<div >
<h2 style={{
            background:`url(${HomeClinicUnderLine})`,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "bottom", 
                            paddingBottom: "25px", textAlign:'center', color:'#9b2687', fontSize:'32px', fontFamily:'Exo, sans-serif'}}>
                                    Contact Us</h2>
                                    </div>
<div id='DetailsDiv' >
                <div class="wrap mcb-wrap one-second  valign-middle clearfix">
                    <div class="mcb-wrap-inner">
<div class="column mcb-column one column_column  column-margin-">
    <div class="column_attr clearfix" >
        <h3 style={{color:'#9b2687', fontFamily:'Exo, sans-serif', fontSize:'24px'}}>Get In Touch</h3>
<p style={{fontSize:'90%', color:'#626262', fontFamily:'Roboto, sans-serif'}}>Want to get in touch? We'd love to hear from you</p>
<div lang="en-US" dir="ltr">
{/* <form action="/contact-us/#wpcf7-f64-p88-o1" method="post" novalidate="novalidate" data-status="init"> */}
<form ref={form} onSubmit={sendEmail}>

{/* <div style={{display: "none"}}>
<input type="hidden" name="_wpcf7" value="64"/>
<input type="hidden" name="_wpcf7_version" value="5.5.3"/>
<input type="hidden" name="_wpcf7_locale" value="en_US"/>
<input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f64-p88-o1"/>
<input type="hidden" name="_wpcf7_container_post" value="88"/>
<input type="hidden" name="_wpcf7_posted_data_hash" value=""/>
</div> */}
<p><label>
    <span >
        <input name="from_name" style={{fontSize:'80%', fontFamily:'Roboto, sans-serif' ,width:'100%', paddingTop:'7px', paddingBottom:'7px', color:'#6792e5', backgroundColor:'whiite'}} type="text"  placeholder="Your name" size="40"  aria-required="true" aria-invalid="false"/>
</span> </label></p>
<p><label>
    <span class="wpcf7-form-control-wrap your-email">
        <input name = "email" style={{fontSize:'80%', fontFamily:'Roboto, sans-serif', width:'100%', paddingTop:'7px', paddingBottom:'7px', color:'#6792e5', backgroundColor:'whiite'}} type="email"  placeholder='Your email' size="40" aria-required="true" aria-invalid="false"/>
        </span> </label></p>
<p><label>
    <span class="wpcf7-form-control-wrap Mobile-number">
        <input name="phone" style={{fontSize:'80%', fontFamily:'Roboto, sans-serif', width:'100%', paddingTop:'7px', paddingBottom:'7px', color:'#6792e5', backgroundColor:'whiite'}} type="tel"  placeholder="Your Contact Number (optional)" size="40" className="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-tel" aria-invalid="false"/>
        </span></label></p>
<p><label>
    <span class="wpcf7-form-control-wrap your-message">
        <textarea name = "message" style={{fontSize:'80%', fontFamily:'Roboto, sans-serif', width:'100%', paddingTop:'7px', paddingBottom:'7px', color:'#6792e5', backgroundColor:'whiite'}}  cols="40" rows="10"  aria-required="true" aria-invalid="false">Your message (optional)</textarea>
    </span> </label></p>
<p>
    {/* <input  style={{ fontSize:'80%', fontFamily:'Roboto, sans-serif' , background:'#e0f0f9', paddingTop:'12px', paddingBottom:'12px', paddingLeft:'18px', paddingRight:'18px', borderRadius:'4px', borderColor:'#9b2687', backgroundColor:'#e0f0f9',  color:'#9b2687', backgroundColor:'whiite'}} type="submit" value="Submit" /> */}
    <BootstrapButton type='submit'   variant="outlined">Submit</BootstrapButton>
    <span class="wpcf7-spinner"></span></p>
<div class="wpcf7-response-output" aria-hidden="true"></div>
</form>

</div>
</div>
</div>
</div></div>






<div>
    <div class="mcb-wrap-inner">
        <div style={{paddingLeft:'5%'}} >
            <h3 style={{fontSize:'28px',fontFamily:'Exo, sans-serif', color:'#626262', textAlign:'left'}}>Reach Us</h3>
            <div >
                <ul style={{display:'flex', flexDirection:'column'}}>
                <li style={{  display:'inline', display:'flex',marginTop:'25px'}} >
                        <FontAwesomeIcon icon={faMapMarker} />
                        <p  style={{color:"#626262", marginLeft:24, fontSize:'13px'}}>
              Canmed Healthcare<br></br>
              BMS College of Engineering<br></br> 
              5th Floor, PG Block <br></br>
              Bull Temple Road, Basavangudi<br></br> 
              Bengaluru - 560 019. Karnataka, India</p>
              </li>
              
              {/* //This is second Loc.. */}
              <li style={{  display:'inline', display:'flex',marginTop:'25px'}} >
                        <FontAwesomeIcon icon={faMapMarker} />
                        <p  style={{color:"#626262", marginLeft:24, fontSize:'13px'}}>
              Canmed Healthcare<br></br>
              C34, 1st Floor, Medivalley,<br></br> 
              World Incubation Hub,<br></br>
              AMTZ Campus, <br></br> 
              Visakhapatnam - 530031.
            Andra Pradesh, India</p>
              </li>
<li style={{display:'flex',flexDirection:'row',marginTop:'25px'}}>
<div style={{display:'flex', flexDirection:'row', alignItems:'start', width:'100%', height:'100%'}}>
<FontAwesomeIcon icon={faPhone} />
<a style={{textDecoration:'none', color:'rgb(98, 98, 98)', marginLeft:24, fontSize:'13px'}} href="tel:+917200889009">
    +91 7200 88 9009
    </a>
</div>
</li>
    <li style={{display:'flex', flexDirection:'row', marginTop:'25px'}}>
    <div style={{display:'flex', flexDirection:'row', alignItems:'start', width:'100%', height:'100%'}}>
<FontAwesomeIcon icon={faEnvelope} />
        <a style={{textDecoration:'none', color:'rgb(98, 98, 98)', marginLeft:24, fontSize:'13px'}} href="mailto:info@canmedhc.com">info@canmedhc.com</a>
        </div>
        </li>
        <li style={{ display:'flex', flexDirection:'row',marginTop:'25px'}}>
            <div style={{display:'flex', flexDirection:'row', alignItems:'start', width:'100%', height:'100%'}}>
<FontAwesomeIcon icon={faLink} />
                <a style={{  textDecoration:'none', color:'rgb(98, 98, 98)', marginLeft:24, fontSize:'13px'}} target="_blank" href="https://www.canmedhc.com">www.canmedhc.com
                    </a>
                </div>
                </li>
                </ul>
                </div>
</div></div></div>


</div>


</div>


</div>


<div style={{ display:'flex', width:'100%', flexDirection:'column', fontSize:'12px',  alignItems:'center', paddingTop:"70px", paddingBottom:"30px",  backgroundColor:"#e0f0f9"}}>
  <p style={{}}>© 2021 Canmed Healthcare Bangalore. All Rights Reserved </p>
</div>

    </>
);

}

export default Contactus;