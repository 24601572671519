import Innovation from './Img/Innovation.png';
import QualityImage from './Img/Quality.png';
import Reliability from './Img/Reliability.png';
import HomeClinicUnderLine from './Img/home_clinic_hr_l.png';
import SliderView from './Img/Slider-Resized-v3.png';
import SliderViewNineTwenty from './Img/Slider-Resized-v3-1920x750.png'
import SliderViewMinusThree from './Img/Slider-Resized-v3-300x183.png';
function Home(){
  const css = `
#DetailsDiv{
  display: flex; 
  flex-direction: row; 
  align-items: center; 
  width: 100%;
}
#DetailsDivQ{
  display: flex; 
  flex-direction: row; 
  align-items: center; 
  width: 100%;
}
#resizeImage{
  height: 100%;
  width: 100%;
  max-width:564px;
  max-height:366px;
}
#DetailsDivR{
  display: flex; 
  flex-direction: row; 
  align-items: center; 
  width: 100%;
}

.canmed-image-overlay{
  width: 60% ;  
  height:520px; 
  margin-left:20px; 
  display: flex; 
  flex-direction: column; 
  justify-content:center;
}
  .backimage{
    background-color: #263a78;
    background-image:url(${SliderView});
    background-repeat: no-repeat;
    background-position: center;
    background-size:cover;
    background-attachment: fixed;
  }
  
  

  @media (max-width: 1020px){
    .backimage{
      // background-image:url("https://picsum.photos/200/300");
      // background-color:'red';
    }
      #DetailsDiv{
        display: flex; 
        flex-direction: column; 
        align-items: center; 
        width: 100%;
      }
      #DetailsDivQ{
        display: flex; 
        flex-direction: column-reverse; 
        align-items: center; 
        width: 100%;
      }
      #DetailsDivR{
        display: flex; 
        flex-direction: column; 
        align-items: center; 
        width: 100%;
      }
    }
  }
  @media (max-width: 650px) {
    // .backimage {
    //     background-image: url("https://picsum.photos/200/300")
    // }
  }
  @media (min-width: 750px) {
    // .backimage {
    //     background-image: url("https://picsum.photos/200");
    // }
}`;
return (
<>
<style scoped>{css}</style>
<div className='backimage'

// style={{
//         backgroundColor: "#263a78",
//         backgroundImage:`url(${SliderView})`,
//         backgroundRepeat: "no-repeat",
//         backgroundPosition: "center",
//         backgroundAttachment: "fixed",
// }} 
>
<div class="canmed-image-overlay">
              <h2 style={{color: "#fff", fontSize: "3.8em", lineHeight: 1, textAlign:"left", fontFamily:'Exo, sans-serif, Arial, Tahoma', fontStyle:'normal',flexDirection:'row',alignItems:'flex-start'}}>
                Welcome to Canmed Healthcare
                </h2>   
                <h5 style={{color: "#fff",fontSize: "0.9375em", lineHeight: '19px', fontSize: "15px", fontFamily:'Roboto,sans-serif,', fontWeight: "500", letterSpacing: "1px", textAlign:"left"}}>
                We specialize in manufacturing Medical devices in the field of Oncology. We offer very high-quality Medical devices and single-use products.
                </h5>   
            </div>
            <div style={{paddingBottom:'30px', paddingTop:'70px',backgroundColor:'f1faff', display:'flex', flexDirection:'column', justifyContent:'center', backgroundColor:'#f1faff'}}>
            <h2 style={{alignSelf:'center', fontSize:'30px', fontStyle:'normal', color:'#9b2687', backgroundImage:`url(${HomeClinicUnderLine})`,backgroundRepeat: "no-repeat", backgroundPositionY:'40px', backgroundPosition: "center",  fontFamily:'Exo, sans-serif, Arial, Tahoma', paddingBottom: "35px"}}>
              Canmed Healthcare
            </h2>
              <p class="big" style={{ fontFamily:'Roboto, sans-serif', textAlign:'center', alignSelf:'center', paddingLeft:'5%', paddingRight:'5%', fontStyle:'normal',fontSize:"14px", lineHeight:'180%',color:'#626262'}}>Canmed Healthcare is a medical device manufacturing company based in Bengaluru, India. With 2 years of Research since 2019, progress and innovation derived from 22 years of experience and know-how required for modern day medical technology, We got Established in June 2021. The satisfaction of our customers and their patients, are our top priority. We continue to be a reliable and trusted partner before, now and in the future.</p>
              <p class="big" style={{ fontFamily:'Roboto, sans-serif', textAlign:'center', alignSelf:'center', paddingLeft:'5%', paddingRight:'5%', fontStyle:'normal',fontSize:"14px", lineHeight:'180%', color:'#626262'}}>Canmed Healthcare is focused on the research and development of innovative products in the field of Oncology.</p>
  </div>
            
<div style={{paddingTop:"70px", paddingBottom:"30px", backgroundColor:"#e0f0f9"}}>

<div id='DetailsDiv' >
<img id='resizeImage' class="scale-with-grid" src={Innovation} alt="Innovation" />

<div style={{display:'flex', flexDirection:'column', alignItems:'start', width:'100%'}}>

<h3 style={{ fontFamily:'Exo, sans-serif, Arial, Tahoma', paddingLeft:'5%', paddingRight:'5%', color:'#9b2687', fontSize:'32px'}}>Innovation</h3>
<p class="big" style={{ fontFamily:'Roboto, sans-serif',paddingLeft:'5%', paddingRight:'5%', textAlign:'justify', fontSize:'100%', lineHeight:'2', color:'#626262'}}>The development and innovation of our products is an essential part of our daily activities. Our goal is to develop (therapeutic) products and solutions that optimize the safety and efficacy of a treatment and simultaneously improve the patient's health.
We are always eager to explore new technologies and create innovative applications.  As a result, we are now in the great position to launch patented product, while still having innovative product pipeline for the future.
We do everything possible to realize innovative and affordable solutions. All of our efforts are subject to high ethical and moral standards.
</p>
</div>
</div>
</div>

<div style={{paddingTop:"70px", paddingBottom:"30px", backgroundColor:"#e0f0f9"}}>

<div id="DetailsDivQ" >

<div style={{display:'flex', flexDirection:'column', alignItems:'start', width:'100%'}}>

<h3 style={{ fontFamily:'Exo, sans-serif', paddingLeft:'5%', paddingRight:'5%', color:'#9b2687', fontSize:'32px'}}>Quality</h3>
<p class="big" style={{fontFamily:'Roboto,sans-serif',color:'#626262',paddingLeft:'5%', paddingRight:'5%', textAlign:'justify', fontSize:'100%', lineHeight:'2'}}>
CANMED, has the aspiration to increase the image as a high-quality supplier of Oncology equipment, Single-use devices, and accessories. In order to comply with high internal standards, we only use high-grade materials from reliable suppliers and apply the latest manufacturing methods. We thoroughly ensure that our high expectations of quality are maintained. Every single product is the result of years of product development, continuous improvements, production, and 100 % quality control in our own facilities. The quality policy includes achieving customer satisfaction through good insight in our customer's requirements
</p>
</div>
<div style={{display:'flex', flexDirection:'column', alignItems:'center', width:'100%'}}>
<img id='resizeImage' class="scale-with-grid" src={QualityImage} alt="Quality" />
</div>

</div>
</div>

<div style={{paddingTop:"70px", paddingBottom:"30px", backgroundColor:"#e0f0f9"}}>
<div id="DetailsDivR" >
<img id='resizeImage' class="scale-with-grid" src={Reliability} alt="Reliability" width="564" height="366"/>
<div style={{display:'flex', flexDirection:'column', alignItems:'start', width:'100%'}}>
<h3 style={{fontFamily:'Exo, sans-serif',color:'#626262',paddingLeft:'5%', paddingRight:'5%', color:'#9b2687', fontSize:'32px'}}>Reliability</h3>
<p class="big" style={{color:'#626262',fontFamily:'Roboto,sans-serif',paddingLeft:'5%', paddingRight:'5%', textAlign:'justify', fontSize:'100%', lineHeight:'2'}}>
We always listen to the market needs, work with doctors and medical professionals to identify new products, techniques and solutions. The satisfaction of our customers and their patients, are our top priority. Our customers expect nothing less than world-class products and services, in this sense, we face the challenges of the market every day and will continue to be a reliable and fair partner for now and in Future. Our distribution Channel partners receive regular, meticulous training in product and service in order to offer our customers a world-class service to go along with our quality products.
</p>
</div>
</div>

</div>

<div style={{ display:'flex', width:'100%', flexDirection:'column', fontSize:'12px',  alignItems:'center', paddingTop:"70px", paddingBottom:"30px",  backgroundColor:"#e0f0f9"}}>
  <p style={{}}>© 2021 Canmed Healthcare Bangalore. All Rights Reserved </p>
</div>
</div>
</>
);
}
export default Home;