import HomeClinicUnderLine from './Img/home_clinic_hr_l.png';
import BMS from './Img/BMS-L.png';
import IndiaMap from './Img/India-Map.png';
import { Link } from 'react-router-dom';
import AMTZ from './Img/image004.png';

function Partners(){

    const css = `

    #indiaImage{
        content: url(${IndiaMap});
        height: 100%;
        width: 100%;
        max-width:865px;
        max-height:1000px;
    }
    #imgcolleges{
        display: flex;
        flex-direction: row;
        align-items:center;
        justify-content:space-evenly;
    }
      
    @media (max-width: 600px){
        #imgcolleges{
            display: flex;
            flex-direction: column;
            align-items:center;
            justify-content:space-evenly;
        }
       
    }`;
  
return(
<>
<style scoped>{css}</style>
<div style={{ paddingLeft:'5%', paddingRight:'5%', paddingTop:"70px",paddingBottom:"30px",backgroundColor:"#e0f0f9"}}>
                <h2 style={{
                    fontFamily:'Exo, sans-serif',
                    fontSize:'32px',
                     background:`url(${HomeClinicUnderLine})`,
                     backgroundRepeat: "no-repeat",
                     backgroundPosition: "bottom",
                    textAlign:"center",color:'#9b2687'}}>
                    Associate Partners
                </h2>
                <div id='imgcolleges' >
                    <img class="scale-with-grid" src={BMS} alt="" style={{maxHeight:'160px',maxWidth:'160px'}} width="50%" height="50%" />      
                    <img class="scale-with-grid"  src={AMTZ} alt="" style={{maxHeight:'100px',maxWidth:'290px', margin:'9px'}} width="60%" height="50%"/>       
                </div>            


<div style={{ paddingTop:"0px", paddingBottom:"0px", backgroundColor:"#e0f0f9"}}>
    <div class="section_wrapper mcb-section-inner">
    <h3 style={{ fontSize:'25px', fontFamily:'Exo, sans-serif',color:'#9b2687', textAlign:'left'}}>Distribution Channel Partners- Locations</h3>
</div>
<div class="wrap mcb-wrap one-third  valign-middle clearfix">
    {/* <img id='indiaImage' class="scale-with-grid" src={IndiaMap} alt=""/> */}
    <img id='indiaImage' alt=""/>
</div>

<div class="wrap mcb-wrap one  valign-top clearfix">
                <p style={{fontSize:'14px',color:'#626262', lineHeight:2, fontFamily:'Poppins, sans-serif'}}>
                We are currently looking for Channel partners in each city, If you are interested please contact us at 
                {/* <img src="https://img.icons8.com/external-flatart-icons-outline-flatarticons/20/000000/external-map-pin-basic-ui-elements-flatart-icons-outline-flatarticons.png"/>
                Bengaluru 
                <img src="https://img.icons8.com/external-flatart-icons-outline-flatarticons/20/000000/external-map-pin-basic-ui-elements-flatart-icons-outline-flatarticons.png"/>
                Mangalore  
                <img src="https://img.icons8.com/external-flatart-icons-outline-flatarticons/20/000000/external-map-pin-basic-ui-elements-flatart-icons-outline-flatarticons.png"/>
                Mysore 
                <img src="https://img.icons8.com/external-flatart-icons-outline-flatarticons/20/000000/external-map-pin-basic-ui-elements-flatart-icons-outline-flatarticons.png"/>
                North Karnataka 
                <img src="https://img.icons8.com/external-flatart-icons-outline-flatarticons/20/000000/external-map-pin-basic-ui-elements-flatart-icons-outline-flatarticons.png"/>
                Chennai 
                <img src="https://img.icons8.com/external-flatart-icons-outline-flatarticons/20/000000/external-map-pin-basic-ui-elements-flatart-icons-outline-flatarticons.png"/>
                Pondicherry 
                <img src="https://img.icons8.com/external-flatart-icons-outline-flatarticons/20/000000/external-map-pin-basic-ui-elements-flatart-icons-outline-flatarticons.png"/>
                Madurai 
                <img src="https://img.icons8.com/external-flatart-icons-outline-flatarticons/20/000000/external-map-pin-basic-ui-elements-flatart-icons-outline-flatarticons.png"/>
                Trichy 
                <img src="https://img.icons8.com/external-flatart-icons-outline-flatarticons/20/000000/external-map-pin-basic-ui-elements-flatart-icons-outline-flatarticons.png"/>
                Coimbatore 
                <img src="https://img.icons8.com/external-flatart-icons-outline-flatarticons/20/000000/external-map-pin-basic-ui-elements-flatart-icons-outline-flatarticons.png"/>
                A.P – Thirupathy  */}
                <Link to='mailto:info@canmedhc.com'  style={{textDecoration:'none',display:'inline' ,color:'#9b2687'}} > info@canmedhc.com</Link>
                
                </p>
                
{/* <p style={{fontFamily:'Poppins, sans-serif',fontSize:'13px', color:'#626262'}}>We are looking for Right channel partners, For queries you can please  */}
{/* <a href="/contact-us"> contact us</a>  */}

{/* <Link to='/contactus'  style={{textDecoration:'none',display:'inline' ,color:'#9b2687', fontFamily:'Poppins, sans-serif'}} > contact us</Link> */}
{/* </p> */}
</div>
</div>
</div>

<div style={{ display:'flex', width:'100%', flexDirection:'column', fontSize:'12px',  alignItems:'center', paddingTop:"70px", paddingBottom:"30px",  backgroundColor:"#e0f0f9"}}>
  <p style={{}}>© 2021 Canmed Healthcare Bangalore. All Rights Reserved </p>
</div>

</>
);

}

export default Partners;