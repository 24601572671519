import logo from './logo.svg';
import canmedlogo from './Img/canmed-logo-large.png';
import './App.css';
import {BrowserRouter as Router, Route,Routes, Link, UNSAFE_RouteContext} from 'react-router-dom';
import { useState } from 'react';
import './navbar.css'
import Home from './Home';
import SliderView from './Img/Slider-Resized-v3.png';
import HomeClinicUnderLine from './Img/home_clinic_hr_l.png';
import HomeClinicStaff from './Img/home_clinic_staff_bg.jpg';
import Innovation from './Img/Innovation.png';
import QualityImage from './Img/Quality.png';
import Reliability from './Img/Reliability.png';
import Products from './Products';
import Partners from './Partners';
import Contactus from './Contactus';
import Aboutus from './Aboutus';
import { HashLink } from 'react-router-hash-link';
import { IconButton, ListItem } from '@mui/joy';
// import Menu from '@mui/joy/Menu';
import Drawer from '@mui/joy/Drawer';
import Typography from '@mui/joy/Typography';
import List from '@mui/joy/List';
import ListItemButton from '@mui/joy/ListItemButton';
import Box from '@mui/joy/Box';
import ModalClose from '@mui/joy/ModalClose';
// import { Menu } from '@mui/material';
import Menu from './Img/menu.png';
import ListSubheader from '@mui/joy/ListSubheader';
// import Menu from '@mui/icons-material/Menu';

function App() {
  const [open, setOpen] = useState(false);

  const CloseDrawer = ()=>{
    setOpen(false);
  }
  const css = `
  #logo-main{
    width: 100%;
    height: 100%;
  }
  #navbarContainer{
    height: 100%;
    width: 100%; 
    display: flex; 
    flex-direction:row; 
    align-items: center; 
    justify-content:center;
  }

  #drawer{
    display:none;
  }
  .hoverText{
    font-size: 14px;
    display:inline; 
    text-decoration: none;
    color: #6792e5;
  }
  .hoverText:hover{
    font-size: 14px;
    display:inline; 
    text-decoration: none;
    color: #9b2687;
  }

  @media (max-width: 1020px){
    .backimage{
      // background-image:url("https://picsum.photos/200/300");
      // background-color:'red';
    }
    #logo-main{
      width: 60%;
      height: 60%;
    }

    #navbarContainer{
      height: 100%;
      width: 100%; 
      display: flex; 
      flex-direction:row; 
      align-items: center; 
      justify-content:center;
      display:none;
    }

    #drawer{
      display:inline;
      margin-right:10px;
    }


  }
  @media (max-width: 650px) {
    // .backimage {
    //     background-image: url("https://picsum.photos/200/300")
    // }
  }
  @media (min-width: 750px) {
    // .backimage {
    //     background-image: url("https://picsum.photos/200");
    // }
}`;
  return (

    <Router >
      <style scoped>{css}</style>
        <div>
      <div style={{width:'100%',height:'100px',backgroundColor:'#ffffff', display:'flex', flexDirection:'row', justifyContent:'center', alignContent:'center', alignItems:'center'}}>
       
      <a id="logo" href="https://canmedhc.com" title="Canmed Healthcare" style={{marginLeft:'10px',display:'flex', alignItems:'center'}}>
        <img id="logo-main" src={canmedlogo} alt="CANMED Health Care Bangalore- Logo" />
      </a>
<div id="drawer" >
<IconButton variant="outlined" color="neutral" onClick={() => setOpen(true)}>
        {/* <Menu /> */}
        <img src={Menu} style={{height:'20px', width:'20px'}} />
      </IconButton>
      <Drawer open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
            ml: 'auto',
            mt: 1,
            mr: 2,
          }}
        >
          <Typography
            component="label"
            htmlFor="close-icon"
            fontSize="sm"
            fontWeight="lg"
            sx={{ cursor: 'pointer' }}
          >
            Close
          </Typography>
          <ModalClose id="close-icon" sx={{ position: 'initial' }} />
        </Box>
       
        <List
          size="lg"
          component="nav"
          sx={{
            flex: 'none',
            fontSize: 'xl',
            '& > div': { justifyContent: 'start' },
          }}
        >
          <ListItemButton><Link to='/' onClick={CloseDrawer} onKeyDown={CloseDrawer} style={{width:'100%',color:'#6792e5', textDecoration:'none'}}>Home</Link></ListItemButton>

          <ListItem nested>
          <ListSubheader>About US</ListSubheader>         
          <ListItemButton>
            <HashLink to="/aboutus#missionVision" onClick={CloseDrawer} onKeyDown={CloseDrawer} style={{width:'100%',color:'#6792e5', textDecoration:'none'}}>Mission Vision</HashLink>
           </ListItemButton>
           <ListItemButton>
            <HashLink to="/aboutus#valueStrength" onClick={CloseDrawer} onKeyDown={CloseDrawer}  style={{width:'100%',color:'#6792e5', textDecoration:'none'}}>Values & Strength</HashLink>
           </ListItemButton>
           <ListItemButton>
            <HashLink to="/aboutus#ourCustomers" onClick={CloseDrawer} onKeyDown={CloseDrawer}  style={{width:'100%',color:'#6792e5', textDecoration:'none'}}>Our Customers</HashLink>
           </ListItemButton>
           <ListItemButton>
            <HashLink to="/aboutus#milestones" onClick={CloseDrawer} onKeyDown={CloseDrawer}  style={{width:'100%',color:'#6792e5', textDecoration:'none'}}>Milestones</HashLink>
           </ListItemButton>
           </ListItem>

          <ListItem nested>
          <ListSubheader>Products</ListSubheader>   
          <ListItemButton>
          <Link to='/products' onClick={CloseDrawer} onKeyDown={CloseDrawer}  style={{width:'100%',display:'inline',textDecoration:'none',color:'#6792e5'}}>Oral Cooling System</Link>
          </ListItemButton>      
          </ListItem>

          <ListItem nested>
          <ListSubheader>Partners</ListSubheader>   
          <ListItemButton>
          <Link to='/partners' onClick={CloseDrawer} onKeyDown={CloseDrawer}  style={{width:'100%',display:'inline',textDecoration:'none',color:'#6792e5'}}>Locations</Link>
          </ListItemButton>      
          </ListItem>

          <ListItemButton><Link to='/contact' onClick={CloseDrawer} onKeyDown={CloseDrawer} style={{width:'100%',color:'#6792e5', textDecoration:'none'}}>Career & Contact US</Link></ListItemButton>

        </List>
      </Drawer>

</div>
        <ul id='navbarContainer' >
            <li class="navLinks" style={{ display:'inline', height:'105px', display:'flex', flexDirection:'column', justifyContent:"center", margin:10}}>
              <Link class ='hoverText' to='/'  >
                Home
                </Link>  
                </li>
              <view style={{ opacity:'0.2', height: '65%',width: 0.5,backgroundColor: '#909090',}}></view>
            <li style={{display:'inline' , margin:10}}>     
                  {/* <Link to='/partners'  
                  style={{ textDecoration:'none',display:'inline' ,color:'#6792e5'}}>
                    About Us</Link> */}

              <div class="dropdown">
                <button class="dropbtn" style={{fontSize:'14px'}}>About Us
                  <i class="fa fa-caret-down"></i>
                </button>
                <div class="dropdown-content">
                  <a><HashLink to="/aboutus#missionVision">Mission Vision</HashLink></a>
                  <a><HashLink to="/aboutus#valueStrength">Values & Strength</HashLink></a>
                  <a><HashLink to="/aboutus#ourCustomers">Our Customers</HashLink></a>
                  <a><HashLink to="/aboutus#milestones">Milestones</HashLink></a>
                </div>
              </div>
          </li>
            <view style={{  opacity:'0.2',height: '65%',width: 0.2,backgroundColor: '#909090',}}></view>
          <li style={{display:'inline', margin:10}}>
                  {/* <Link to='/about'  style={{display:'inline' , textDecoration:'none',color:'#6792e5'}}>Products</Link> */}
            <div class="dropdown">
              <button class="dropbtn" style={{fontSize:'14px'}}>Products
                <i class="fa fa-caret-down"></i>
              </button>
              <div class="dropdown-content">
                <a href="#"> <Link to='/products'  style={{display:'inline' , textDecoration:'none'}}>Oral Cooling System</Link></a>
              </div>
            </div>
          </li>
                <view style={{  opacity:'0.2',height: '65%',width: 0.2,backgroundColor: '#909090',}}></view>
          <li style={{display:'inline', margin:10}}>
            {/* <Link to='/products'  style={{display:'inline' , textDecoration:'none',color:'#6792e5'}}>Partners</Link> */}
            <div class="dropdown">
              <button class="dropbtn" style={{fontSize:'14px'}}>Partners
                <i class="fa fa-caret-down"></i>
              </button>
              <div class="dropdown-content">
                <a href="#"><Link to='/partners'  style={{display:'inline' , textDecoration:'none'}}>Locations</Link></a>
              </div>
            </div>
          </li>
                <view style={{  opacity:'0.2',height: '65%',width: 0.2,backgroundColor: '#909090',}}></view>
                <li class="navLinks" style={{ height:'105px', display:'inline', display:'flex', flexDirection:'column', justifyContent:'center', margin:10}}>
                  <Link to='/contact' class='hoverText' >
                    Career & Contact US
                    </Link>
                    </li>
          </ul>

   {/* <div class="topnav" id="myTopnav">
  <a href="#home" class="active">Home</a>
  <a href="#news">News</a>
  <a href="#contact">Contact</a>
  <div class="dropdown">
    <button class="dropbtn">Dropdown
      <i class="fa fa-caret-down"></i>
    </button>
    <div class="dropdown-content">
      <a href="#">Link 1</a>
      <a href="#">Link 2</a>
      <a href="#">Link 3</a>
    </div>
  </div>
  <a href="#about">About</a>
  <a href="javascript:void(0);" class="icon" onclick="myFunction()">&#9776;</a>
</div> */}

            </div>
            {/* <div style={{width:"60%", marginLeft:'20px'}}>
              <h2 style={{color: "#fff", fontSize: "54px", lineHeight: "54px", textAlign:"left"}}>
                Welcome to Canmed Healthcare
                </h2>   
                <h5 style={{color: "#fff",fontSize: "15px", lineHeight: "19px", fontSize: "15px", fontWeight: "500", letterSpacing: "1px", textAlign:"left"}}>
                We specialize in manufacturing therapeutic systems and devices in the field of Oncology. We offer very high-quality Medical devices and single-use products.
                </h5>   
            </div>
            <div style={{paddingBottom:'30px', paddingTop:'70px',backgroundColor:'f1faff', display:'flex', flexDirection:'column', justifyContent:'center', backgroundColor:'#f1faff'}}>
            <h2 style={{alignSelf:'center', fontSize:'30px', fontStyle:'normal', color:'#9b2687', backgroundImage:`url(${HomeClinicUnderLine})`,backgroundRepeat: "no-repeat", backgroundPosition: "center",paddingBottom: "25px"}}>
              Canmed Healthcare</h2>
              <p class="big" style={{ textAlign:'center', alignSelf:'center', paddingLeft:'5%', paddingRight:'5%', fontStyle:'normal',fontSize:"12px", lineHeight:'180%'}}>Canmed Healthcare is a medical device manufacturing company based in Bengaluru, India. With 2 years of Research since 2019, progress and innovation derived from 22 years of experience and know-how required for modern day medical technology, We got Established in June 2021. The satisfaction of our customers and their patients, are our top priority. We continue to be a reliable and trusted partner before, now and in the future.</p>
              <p class="big" style={{ textAlign:'center', alignSelf:'center', paddingLeft:'5%', paddingRight:'5%', fontStyle:'normal',fontSize:"12px", lineHeight:'180%'}}>Canmed Healthcare is focused on the research and development of innovative products in the field of Oncology.</p>
            </div>
<div style={{paddingTop:"70px", paddingBottom:"30px", backgroundColor:"#e0f0f9"}}>
<div style={{display:'flex', flexDirection:'column', alignItems:'center', width:'100%'}}>
<img class="scale-with-grid" src={Innovation} alt="Innovation" width="564" height="366"/>
</div>
<h3 style={{paddingLeft:'5%', paddingRight:'5%', color:'#9b2687', fontSize:'20px'}}>Innovation</h3>
<p class="big" style={{paddingLeft:'5%', paddingRight:'5%', textAlign:'justify', fontSize:'15px', lineHeight:'2'}}>The development and innovation of our products is an essential part of our daily activities. Our goal is to develop (therapeutic) products and solutions that optimize the safety and efficacy of a treatment and simultaneously improve the patient's health.
We are always eager to explore new technologies and create innovative applications.  As a result, we are now in the great position to launch patented product, while still having innovative product pipeline for the future.
We do everything possible to realize innovative and affordable solutions. All of our efforts are subject to high ethical and moral standards.
</p>
</div>

<div style={{paddingTop:"70px", paddingBottom:"30px", backgroundColor:"#e0f0f9"}}>
<h3 style={{paddingLeft:'5%', paddingRight:'5%', color:'#9b2687', fontSize:'20px'}}>Quality</h3>
<p class="big" style={{paddingLeft:'5%', paddingRight:'5%', textAlign:'justify', fontSize:'15px', lineHeight:'2'}}>
CANMED, has the aspiration to increase the image as a high-quality supplier of Oncology equipment, Single-use devices, and accessories. In order to comply with high internal standards, we only use high-grade materials from reliable suppliers and apply the latest manufacturing methods. We thoroughly ensure that our high expectations of quality are maintained. Every single product is the result of years of product development, continuous improvements, production, and 100 % quality control in our own facilities. The quality policy includes achieving customer satisfaction through good insight in our customer's requirements
</p>
<div style={{display:'flex', flexDirection:'column', alignItems:'center', width:'100%'}}>
<img class="scale-with-grid" src={QualityImage} alt="Quality" width="564" height="366" />
</div>
</div>

<div style={{paddingTop:"70px", paddingBottom:"30px", backgroundColor:"#e0f0f9"}}>
<div style={{display:'flex', flexDirection:'column', alignItems:'center', width:'100%'}}>
<img class="scale-with-grid" src={Reliability} alt="Reliability" width="564" height="366"/>
</div>
<h3 style={{paddingLeft:'5%', paddingRight:'5%', color:'#9b2687', fontSize:'20px'}}>Reliability</h3>
<p class="big" style={{paddingLeft:'5%', paddingRight:'5%', textAlign:'justify', fontSize:'15px', lineHeight:'2'}}>
We always listen to the market needs, work with doctors and medical professionals to identify new products, techniques and solutions. The satisfaction of our customers and their patients, are our top priority. Our customers expect nothing less than world-class products and services, in this sense, we face the challenges of the market every day and will continue to be a reliable and fair partner for now and in Future. Our distribution Channel partners receive regular, meticulous training in product and service in order to offer our customers a world-class service to go along with our quality products.
</p>
</div>

<div style={{ display:'flex', width:'100%', flexDirection:'column', fontSize:'12px',  alignItems:'center', paddingTop:"70px", paddingBottom:"30px",  backgroundColor:"#e0f0f9"}}>
  <p style={{}}>© 2021 Canmed Healthcare Bangalore. All Rights Reserved </p>
</div> */}

</div>

  <Routes>       
      <Route path='/' exact element={<Home />} />
      <Route path='/aboutus' element={<Aboutus />} />
      <Route path='/products' element={<Products />} />
      <Route path='/partners' element={<Partners />} />
      <Route path='/contact' element={<Contactus />} />
    </Routes>
</Router>
  );
}

export default App;
