
import HomeClinicUnderLine from './Img/home_clinic_hr_l.png';

function Products(){

return(
<>
<div style={{paddingTop:"70px", paddingBottom:"30px", backgroundColor:"#e0f0f9"}}>
    <div >
        <div class="wrap mcb-wrap one  valign-top clearfix" >
            <div style={{paddingLeft:'5%', paddingRight:'5%'}}>
                    <h2 style={{
                         background:`url(${HomeClinicUnderLine})`,
                         backgroundRepeat: "no-repeat",
                         backgroundPosition: "bottom",
                         paddingBotttom: "25px",fontWeight:'bold', paddingLeft:'5%', paddingRight:'5%', color:'#9b2687', textAlign:'center', fontSize:'42px', fontFamily:'Exo, sans-serif'}}>Products</h2>
        
                    <p style={{fontFamily:'Roboto, sans-serif', color:'#626262',fontSize:'90%', lineHeight:2}}>In Medical Device industry products needs safety and quality with precision in manufacturing, keeping this in mind, with Concept, innovation and best engineering we constantly and consistently develop our products at Canmed Healthcare.</p>

            <div class="column_attr clearfix align_center">
            <h3 style={{ fontFamily:'Exo, sans-serif',fontSize:'32px',color:'#9b2687', textAlign:'center'}}>Oral Cooling System</h3>
            <p style={{color:'#626262', fontSize:'23px',textAlign: 'center', fontWeight:'bolder', color:'#9b2687', fontFamily:'Exo,sans-serif'}}>
            A treatment to prevent Oral Mucositis caused by Chemotherapy/Radiation therapy </p>
            <p style={{color:'#626262', fontSize:'21px',textAlign: 'center', fontWeight:'bolder', textDecoration:'underline', color:'#9b2687', fontFamily:'Exo,sans-serif'}}>
            Oral Mucositis - Effect of Chemotherapy
            </p>
            
                        </div>
                    
    <div>
{/* <h4 style={{ fontSize:'23px',color:'#9b2687', fontFamily:'Exo,sans-serif'}}>Effects of Chemotherapy</h4> */}
{/* <p style={{color:'#626262',fontSize:'80%', lineHeight:2}}>A therapy to prevent Oral Mucositis performed during, pre, and post Chemotherapy </p> */}
<ul  style={{fontFamily:'Roboto, sans-serif', color:'#626262',fontSize:'90%', lineHeight:2}}>
  <li>
  Oral Mucositis occurs as a result of Chemotherapy or Radiation Therapy which causes inflammation and ulcerative lesions that requires high dose of Morphine for pain alleviation.  However, the other risk factor included low Body Mass Index, physical ageing, renal impairment and cumulative radiation dose.  Nearly all patients who receive fractionated radiation for head and neck cancer experience Oral Mucositis andmore than half of the Pediatric patients experience any grade of OM.
  </li>
  <li>
OM results in swallowing impairment and pain that prevents oral intake results in weight loss, anorexia, malnutrition, anemia and fatigue.
OM has a profound effect on Quality of Life (QoL) as it leads to severe pain and requiring feeding tube placement and hospitalization.
</li>
  {/* <li>To date the treatment is Ice chips that has shown to reduce the severity and extent of OM however this results in adverse reactions such us tooth sensation, problems with infectious water causing Nausea and uneven cooling of Oral Mucosa.</li> */}
</ul>

{/* <p style={{color:'#626262', fontSize:'80%',lineHeight:2,textAlign:'justify'}}>As per studies Oral Mucositis is estimated to occur in 20% to 40% of patients receiving conventional chemotherapy and up to 80% of patients receiving high dose chemotherapy. Among pediatric patients treated with chemotherapy, 56% of children experience any grade OM</p> */}
</div>
<div >
<p style={{color:'#626262', fontSize:'21px',textAlign: 'center', fontWeight:'bolder', textDecoration:'underline', color:'#9b2687', fontFamily:'Exo,sans-serif'}}>
ORAL COOLING SYSTEM – A Novel Solution for Oral Mucositis
</p>
       
{/* <h4 style={{color:'#9b2687', fontFamily:'Exo, sans-serif', fontSize:'23px'}}>Oral Cooling System– A Novel Solution for Oral Mucositis</h4> */}
{/* <p style={{color:'#626262',fontSize:'80%',lineHeight:2, textAlign:'justify'}}>A therapy to prevent Oral Mucositis performed during, pre and post Chemotherapy </p> */}
<ul  style={{fontFamily:'Roboto, sans-serif', color:'#626262',fontSize:'90%', lineHeight:2}}>
  <li>A treatment to prevent Oral Mucositis performed during, pre and post Chemotherapy</li>
 <li>Cooling the oral cavity results in Vasoconstriction, decreasing the metabolism that would prevent the oral mucositis</li>
  <li>Oral Cooling System will be a well-tolerated treatment for the patient and expected resultscould be achieved evenly throughout the oral cavity</li>
</ul>
<br/>
<br/>
<br/>
<p style={{fontSize:'21px',color:'#626262',lineHeight:2, textAlign:'center'}}> ** More products are under Research &amp; Developmental Stage. </p>
</div>
</div>
</div>
</div>
</div>
<div style={{ display:'flex', width:'100%', flexDirection:'column', fontSize:'12px',  alignItems:'center', paddingTop:"70px", paddingBottom:"30px",  backgroundColor:"#e0f0f9"}}>
  <p style={{}}>© 2021 Canmed Healthcare Bangalore. All Rights Reserved </p>
</div>

</>

);

}

export default Products;